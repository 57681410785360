<template>
  <main class="content full-height col-centered">
    <h1>Please verify your email</h1>
    <p>We've sent an email to your email address with a link to verify your account.</p>
    <p>You may now close this page.</p>
    <div class="centered">
      <router-link class="button" to="/">Home</router-link>
    </div>
  </main>
</template>
